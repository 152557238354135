import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Landing from "./pages/Landing/LandingPage";
import LandingCNP from "./pages/Landing/LandingPage/Home/landingcnp";
import LandingBB from "./pages/Landing/LandingPage/Home/landingbb";
import LandingAdm from "./pages/Landing/LandingPage/Home/landing";
import Parceiro from "./pages/Landing/LandingPage/Home/parceiro";
import SobreAConCash from "./pages/SobreAConCash";
import Page404 from "./pages/Page404";
import pack from "../package.json";
import OptIn from "./pages/OptIn";
import { SucessoOferta } from "./pages/OptIn/steps/sucess";
import { RejeitouOferta } from "./pages/OptIn/steps/reject";
import VenderConsorcio from "./pages/VenderConsorcio";
import { SucessoVender } from "./pages/VenderConsorcio/steps/sucess";
import FAQ from "./pages/FAQ";
import { NaoAtendeVender } from "./pages/VenderConsorcio/steps/naoAtende";
import LandingRandon from "./pages/Landing/LandingPage/Home/landingrandon";

const RoutesWeb = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={About} path="/sobre" />
        <Route component={Contact} path="/contatos" />
        <Route component={Landing} path="/santander" />
        <Route component={LandingAdm} path="/chevrolet" />
        <Route component={LandingAdm} path="/porto" />
        <Route component={LandingAdm} path="/canopus" />
        <Route component={LandingAdm} path="/volkswagen" />
        <Route component={LandingRandon} path="/randon" />
        <Route component={LandingCNP} path="/cnp" /> 
        <Route component={LandingBB} path="/bb" /> 
        <Route component={Parceiro} path="/parceiro" />
        <Route component={OptIn} path="/oferta" />
        <Route component={FAQ} path="/faq" />
        <Route component={VenderConsorcio} path="/venderconsorcio" />
        <Route component={SucessoOferta} path="/sucesso-oferta" />
        <Route component={SucessoVender} path="/vc-sucesso" />
        <Route component={NaoAtendeVender} path="/nao-atendida" />
        <Route component={RejeitouOferta} path="/rejeitou-oferta" />
        <Route exact path="/consorciogm">
          <Redirect to="/chevrolet" />
        </Route>
        <Route
          path="/health-check"
          exact
          component={() => <h3>Hey ConCash, version {pack.version}!!!</h3>}
        />
        <Route component={SobreAConCash} path="/conac2023/Sobre_Concash" />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesWeb;
